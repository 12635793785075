import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
import * as _defineDataProperty2 from "define-data-property";
var _defineDataProperty = _defineDataProperty2;
try {
  if ("default" in _defineDataProperty2) _defineDataProperty = _defineDataProperty2.default;
} catch (e) {}
import * as _hasPropertyDescriptors2 from "has-property-descriptors";
var _hasPropertyDescriptors = _hasPropertyDescriptors2;
try {
  if ("default" in _hasPropertyDescriptors2) _hasPropertyDescriptors = _hasPropertyDescriptors2.default;
} catch (e) {}
import * as _gopd2 from "gopd";
var _gopd = _gopd2;
try {
  if ("default" in _gopd2) _gopd = _gopd2.default;
} catch (e) {}
import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
var exports = {};
var GetIntrinsic = _getIntrinsic;
var define = _defineDataProperty;
var hasDescriptors = _hasPropertyDescriptors();
var gOPD = _gopd;
var $TypeError = _type;
var $floor = GetIntrinsic("%Math.floor%");

/** @type {import('.')} */
exports = function setFunctionLength(fn, length) {
  if (typeof fn !== "function") {
    throw new $TypeError("`fn` is not a function");
  }
  if (typeof length !== "number" || length < 0 || length > 4294967295 || $floor(length) !== length) {
    throw new $TypeError("`length` must be a positive 32-bit integer");
  }
  var loose = arguments.length > 2 && !!arguments[2];
  var functionLengthIsConfigurable = true;
  var functionLengthIsWritable = true;
  if ("length" in fn && gOPD) {
    var desc = gOPD(fn, "length");
    if (desc && !desc.configurable) {
      functionLengthIsConfigurable = false;
    }
    if (desc && !desc.writable) {
      functionLengthIsWritable = false;
    }
  }
  if (functionLengthIsConfigurable || functionLengthIsWritable || !loose) {
    if (hasDescriptors) {
      define( /** @type {Parameters<define>[0]} */fn, "length", length, true, true);
    } else {
      define( /** @type {Parameters<define>[0]} */fn, "length", length);
    }
  }
  return fn;
};
export default exports;